import { JsxElement } from "typescript";

export default interface TableConfig {
    id: string;
    search?: {
        keys: string[];
        run: (query: string, key: string | null) => void;
    };
    filter?: {
        keys: { key: number; title: string; color: string; count?: number }[];
        run: (key: string) => void;
    };
    serachForDownloadCenter?: boolean;
    searchEmptyCall?: {
        run: () => void;
    };
    download?: {
        title: string;
        run: () => void;
    };
    upload?: {
        title: string;
        run: () => void;
    };
    extraButtonsArray?: {
        name: string;
        onClick: () => void;
        icon: JSX.Element;
    }[];
    expandable: {
        status: boolean;
        dataIndex: string | null;
    };

    sorting: {
        type: SortingType;
    };
    pagination?: {
        count: number;
        run: (page: number, tab: string, size: number, next: boolean) => void;
    };
    globalButtons?: {
        func: string;
        display: {
            text: any;
            condition: any;
        };
    }[];
    checkBox?: Boolean;
    columns: {
        title: string;
        dataIndex?: string;
        search?: boolean;
        required: boolean;
        sort?: SortingType;
        condition?: string;
        order?: SortingOrders;
        width?: string;
        dMap?: object;
        color?: object;
        type?: ColumnTypes;
        render?: JsxElement;
        numberOfButtons?: number;
        format?: (val: string) => string;
        actions?: {
            func: string;
            display: {
                icon: string;
                text: any;
                color?: string;
                condition: any;
            };
        }[];
    }[];
}

export enum SearchType {
    Genral,
    Key,
}

export enum SortingType {
    AlphaNumeric,
    Strategy,
    null,
}

export enum SortingOrders {
    Asc,
    Desc,
}

export enum ColumnTypes {
    String,
    Number,
    Money,
    Action,
    javascriptDateObject,
    Status,
    dd_mmm_yyyy,
    yyyy_mm_dd,
    Relationship,
}
