import Bowser from "bowser";
import advService from "../../../util/advService";
import { USER_PLATFORM_POST } from "../../../util/newconfig";
import { JavaResponse } from "../../../types/JavaRespnse";
import { AxiosResponse } from "axios";

// type : 1 -> App, 2 -> Moibile web, 3 -> Desktop web
type platform = {
    type: 1 | 2 | 3;
    device?: string;
    osType: "mac" | "windows" | "linux" | "iphone" | "android";
    version: string | null;
    handsetType: string | null;
};

export const Platform = {
    getPlatform: (): platform => {
        let isApp = localStorage.getItem("isMobileApp") ? true : false;
        if (isApp) {
            return {
                type: 1,
                device: localStorage.getItem("device") || "ios",
                osType: localStorage.getItem("osType") as platform["osType"],
                version: localStorage.getItem("version") || null,
                handsetType: localStorage.getItem("handsetType") || null,
            };
        }
        let navigatorBrowserData = Bowser.parse(window.navigator.userAgent);
        let isMobile = navigatorBrowserData.platform.type === "mobile";
        let osType = navigatorBrowserData.os.name;
        let version = navigatorBrowserData.os.version;
        return {
            type: isMobile ? 2 : 3,
            osType: osType as platform["osType"],
            version: version || null,
            handsetType: null,
        };
    },

    sendPlatformData: async () => {
            let platformData = Platform.getPlatform();
            advService(
                { ...USER_PLATFORM_POST },
                platformData,
                (response: AxiosResponse<JavaResponse<{}>>) => {
                    if (response.data.success) {
                        return true
                    }
                    return false
                }
            );
        
    },
};
