import React from "react";
import Loader from "../Loader/Loader";
import {
    Box,
    Divider,
    IconButton,
    Modal,
    Stack,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Iconify from "../iconify";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
// const CloseIcon =
//     "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/Modals_icons/Close.svg";
import CloseIcon from "@mui/icons-material/Close";

const GenericModalContainer = ({
    open = true,
    fullView = false,
    noHeader = false,
    hideHeaderDivider = false,
    ...props
}) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    if (isMobile) {
        return (
            <Modal
                open={open}
                onClose={props.close}
                className={`GenericModalContainer ${props.addClass ? props.addClass : ""}`}
                aria-labelledby="modal-modal-title"
                style={
                    fullView
                        ? {
                              width: "100vw",
                              height: "100vh",
                              top: 0,
                              left: 0,
                              margin: 0,
                              padding: 0,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                          }
                        : {}
                }
                aria-describedby="modal-modal-description"
                hideBackdrop={props.hideBackdrop}
            >
                <>
                    <Box
                        sx={{
                            position: fullView ? "fixed" : "absolute",
                            bottom: fullView ? "0" : "0px",
                            left: "50%",
                            transform: "translate(-50%, 0%)",
                            width: fullView ? "100vw" : "100%",
                            height: fullView ? "100vh" : "auto",
                            maxHeight: "100dvh",
                            bgcolor: "background.paper",
                            boxShadow: 24,
                            borderRadius: fullView ? "0" : "24px 24px 0px 0px",
                            p: props?.padding || 4,
                            ...(props?.headerStyle || {}),
                            overflowY: "auto",
                            ".buttons": {
                                margin: 0,
                                paddingTop: "16px",
                                width: "100%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                marginLeft: "auto",
                                borderTop: "dashed 1px #6F6B7A33",
                                "& > :last-child": {
                                    marginLeft: "1em",
                                },
                                "& > :only-child": {
                                    marginLeft: "auto",
                                },
                                "& button": {
                                    height: "48px",
                                    width: "max-content",
                                    paddingLeft: "1em",
                                    paddingRight: "1em",
                                    minWidth: "8em",
                                    display: "inline-block",
                                    color: "#3E3C47 !important",
                                    background: "#FFFFFF",
                                    boxShadow:
                                        "0px 0px 5px 0px rgba(0, 0, 0, 0.1)",
                                    boxSizing: "border-box",
                                    borderRadius: "1000px",
                                    fontSize: "12px",
                                    fontStyle: "normal",
                                    fontWeight: "600 !important",
                                },
                                "& button.primary": {
                                    background: "#6200EA !important",
                                    border: "2px solid #6200EA",
                                    height: "48px",
                                    color: "#FFFFFF !important",
                                    fontSize: "12px",
                                    fontStyle: "normal",
                                    fontWeight: "600 !important",
                                },
                            },
                        }}
                    >
                        {fullView && (
                            <KeyboardBackspaceIcon
                                sx={{
                                    color: theme.palette.grey[800],
                                    cursor: "pointer",
                                    width: "32px",
                                    height: "32px",
                                    marginBottom: "24px",
                                }}
                                onClick={props.close}
                            />
                        )}
                        {!noHeader && (
                            <Stack
                                flexDirection={"row"}
                                width={"100%"}
                                justifyContent={"space-between"}
                                alignItems={"flex-start"}
                            >
                                <Stack flexDirection={"column"}>
                                    <Typography
                                        id="modal-modal-title"
                                        variant="h5"
                                        color={theme.palette.text.primary}
                                        sx={{
                                            fontSize: "20px",
                                            fontStyle: "normal",
                                            fontWeight: "600",
                                        }}
                                    >
                                        {props.headingText}
                                    </Typography>
                                    <Typography
                                        id="modal-modal-description"
                                        variant="body3"
                                        sx={{ mb: 2 }}
                                    >
                                        {props.subHeadingText}
                                    </Typography>
                                </Stack>
                                {!fullView && (
                                    <Iconify
                                        icon="material-symbols:close"
                                        onClick={props.close}
                                        width={"36px"}
                                        height={"36px"}
                                        sx={{
                                            cursor: "pointer",
                                            color: theme.palette.text.primary,
                                            padding: "8px",
                                        }}
                                    />
                                )}
                            </Stack>
                        )}
                        {props.children}
                    </Box>
                </>
            </Modal>
        );
    } else {
        return (
            <Modal
                open={open}
                onClose={props.close}
                className="modal-container modal-container__v2"
                hideBackdrop={props.hideBackdrop}
            >
                <div
                    className={`main-modal delete-modal GenericModalContainer ${
                        props.addClass ? props.addClass : ""
                    } main-modal__v2  ${
                        props.input ? "spaceBetweenButtons noDivider" : ""
                    } ${
                        props.removal
                            ? "spaceBetweenButtons deleteButtons noDivider"
                            : ""
                    } ${props.warning ? "noDivider" : ""}`}
                    style={{ ...props.styles }}
                >
                    <Loader loader={props.loader}>
                        {!noHeader && (
                            <>
                                <div
                                    className="modal-header"
                                    style={{
                                        alignItems: "flex-start",
                                        ...(props?.headerStyle || {}),
                                    }}
                                >
                                    {props.backButton && (
                                        // <IconButton
                                        //     onClick={() => {
                                        //         props.backButton();
                                        //     }}
                                        //     color="primary"
                                        //     component="span"
                                        //     style={{
                                        //         padding: "1rem 1rem",
                                        //         // margin: "-1rem auto -1rem -1rem",
                                        //     }}
                                        // >
                                        //     <ArrowBackIcon />
                                        // </IconButton>

                                        // <Iconify
                                        //     color={"#0C1230"}
                                        //     height={24}
                                        //     width={24}
                                        //     icon="material-symbols:arrow-left-alt"
                                        //     onClick={() => {
                                        //         props.backButton();
                                        //     }}
                                        // />

                                        <KeyboardBackspaceIcon
                                            onClick={() => {
                                                props.backButton();
                                            }}
                                            style={{
                                                cursor: "pointer",
                                                color: "#0C1230",
                                                width: 24,
                                                height: 24,
                                            }}
                                        />
                                    )}
                                    <Box style={{ flex: 1 }}>
                                        <Typography
                                            variant="h5"
                                            className={`headings_font_color ${props?.headingClassName}`}
                                            sx={{
                                                fontSize:
                                                    props?.warning ||
                                                    props?.removal ||
                                                    props?.input
                                                        ? "16px"
                                                        : "20px",
                                                fontStyle: "normal",
                                                fontWeight: "600",
                                                h1: {
                                                    fontSize:
                                                        props?.warning ||
                                                        props?.removal ||
                                                        props?.input
                                                            ? "16px"
                                                            : "20px",
                                                    fontStyle: "normal",
                                                    fontWeight: "600",
                                                    margin: 0,
                                                },
                                            }}
                                        >
                                            <Stack
                                                direction={"row"}
                                                alignItems={"center"}
                                                gap={"8px"}
                                            >
                                                {props?.removal && (
                                                    <Box
                                                        sx={{
                                                            display: "flex",
                                                            alignItems:
                                                                "center",
                                                            padding: "8px",
                                                            backgroundColor:
                                                                "#FFEAEB",
                                                            borderRadius: "50%",
                                                        }}
                                                    >
                                                        <DeleteOutlinedIcon
                                                            sx={{
                                                                color: "#93450C",
                                                            }}
                                                        />
                                                    </Box>
                                                )}

                                                {props?.warning && (
                                                    <Box
                                                        sx={{
                                                            display: "flex",
                                                            alignItems:
                                                                "center",
                                                            padding: "8px",
                                                            backgroundColor:
                                                                "#FFF3E4",
                                                            borderRadius: "50%",
                                                        }}
                                                    >
                                                        <WarningRoundedIcon
                                                            sx={{
                                                                color: "#93450C",
                                                            }}
                                                        />
                                                    </Box>
                                                )}

                                                {props.headingText}
                                            </Stack>
                                        </Typography>
                                        {props.subHeadingText && (
                                            <Typography
                                                id="modal-modal-description"
                                                variant="body3"
                                                sx={{ mb: 2 }}
                                            >
                                                {props.subHeadingText}
                                            </Typography>
                                        )}
                                    </Box>
                                    <span
                                        className="close"
                                        style={{
                                            position: "relative",
                                            zIndex: 998,
                                        }}
                                        onClick={props.close}
                                    >
                                        <CloseIcon
                                            sx={{
                                                color: "#3E3C47",
                                            }}
                                        />
                                    </span>
                                </div>
                                {props.input ||
                                props.removal ||
                                hideHeaderDivider ||
                                props.warning ? null : props.headingText ? (
                                    <Divider
                                        // variant="dashed"
                                        sx={{
                                            // borderColor: "grey.400",
                                            // opacity: 0.3,
                                            marginBottom: "8px",
                                            borderBottom:
                                                "1px dashed #6F6B7A33",
                                        }}
                                    />
                                ) : null}
                            </>
                        )}
                        {props.children}
                    </Loader>
                </div>
            </Modal>
        );
    }
};

export default GenericModalContainer;
