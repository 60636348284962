import Iconify from "../iconify";

const AdimnD = <Iconify icon="mdi:user-circle-outline" />;
    // "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/nav_icons/desktopIcons/admin.svg";
const ClaimsD = <Iconify
icon="solar:shield-check-outline"
// style={commonStyle}
className="pointer"
/> ;
    // "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/nav_icons/desktopIcons/claims.svg";
const HomeD = 
<Iconify icon="solar:home-smile-outline" />;
    // "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/nav_icons/desktopIcons/home.svg";
const HospitalD =
    <Iconify icon="material-symbols:home-health-outline" />;
    // "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/nav_icons/desktopIcons/hospital.svg";
const MembersD =
    <Iconify icon="material-symbols:supervisor-account-outline" />;
    // "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/nav_icons/desktopIcons/members.svg";
const PaymentsD =
    <Iconify icon="material-symbols:payments-outline" />;
    // "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/nav_icons/desktopIcons/payments.svg";
const PlansD =
<Iconify icon="solar:shield-check-outline" />;
    // "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/nav_icons/desktopIcons/plans.svg";
const ReportD = <Iconify icon="solar:document-outline" />;
    // "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/nav_icons/reports.png";
const DownArrow = <Iconify icon="material-symbols:keyboard-arrow-down" />
    // "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/down-arrow.png";
const ParamsD = <Iconify icon="material-symbols:settings-outline" />; // uil:setting
    // "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/nav_icons/params.svg";
const walletD =
<Iconify icon="material-symbols:payments-outline" />;
    // "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/nav_icons/wallet.png";

const actionsM = <Iconify icon="icon-park-outline:check-one" />;
    // "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/nav_icons/mobileIcons/actions.svg";
const agentsM = <Iconify icon="material-symbols:support-agent" />;
    // "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/nav_icons/mobileIcons/agents.svg";
const detailsM = <Iconify icon="material-symbols:info-outline" />;
    // "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/nav_icons/mobileIcons/details.svg";
const insurerM = <Iconify icon="streamline:user-protection-2" />;
    // "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/nav_icons/mobileIcons/insurer.svg";
const leadsM = <Iconify icon="material-symbols:flag-outline" />;
    // "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/nav_icons/mobileIcons/leads.svg";
const newEstimateM = <Iconify icon="fluent:money-calculator-20-regular" />;
    // "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/nav_icons/mobileIcons/newEstimate.svg";
const profileM = <Iconify icon="mdi:user-circle-outline" />;
    // "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/nav_icons/mobileIcons/profile.svg";
const HospitalM = <Iconify icon="material-symbols:home-health-outline" />;
    // "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/nav_icons/mobileIcons/hospital.svg";
const claimReportM = <Iconify icon="icon-park-outline:file-protection" />;
    // "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/nav_icons/claim_report.png";
const appusageM = <Iconify icon="lsicon:pie-one-outline" />;
    // "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/nav_icons/app_usage.png";
const walletM = <Iconify icon="tabler:wallet" />;
    // "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/nav_icons/wallet.png";
const emailData = <Iconify icon="ic:outline-email" />;
    // "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/Email+data.svg";
const enrollment = <Iconify icon="mdi:users-add-outline" />;
    // "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/Enrollment.svg";

const iconCreator = (val) => {
    console.log(val, "val");
    switch (val) {
        case "admin":
            return AdimnD;
        case "claims":
            return ClaimsD;
        case "home":
            return HomeD;
        case "hospitals":
            return HospitalD;
        case "members":
            return MembersD;
        case "payments":
            return PaymentsD;
        case "plans":
            return PlansD;
        case "actions":
            return actionsM;
        case "agents":
            return agentsM;
        case "details":
            return detailsM;
        case "insurer":
            return insurerM;
        case "leads":
            return leadsM;
        case "newEstimate":
            return newEstimateM;
        case "uploadHospital":
            return HospitalM;
        case "reports":
            return ReportD;
        case "claimreport":
            return claimReportM;
        case "appusage":
            return appusageM;
        case "arrowD":
            return DownArrow;
        case "params":
            return ParamsD;
        case "users" || "admin":
            return profileM;
        case "wallet":
            return walletD;
        case "emailData":
            return emailData;
        case "enrollment":
            return enrollment;
        default:
            return HomeD;
    }
};

export default iconCreator;
