import { ReactElement, ReactNode, useEffect, useState, useRef } from "react";
import { Box, Icon, Menu, MenuItem, MenuProps, Popover, TextField } from "@mui/material";
import TableConfig, {
    ColumnTypes,
} from "../../types/Components/Table/TableConfig";
import { Input, Table as AntTable, Button, Tooltip } from "antd";
import ErrorIcon from "@mui/icons-material/Error";
import type { ColumnsType } from "antd/es/table";
import Dropdown from "../Input/Dropdown";
// import { Tabs } from "antd";
import CustomPagination from "../CustomPagination/CustomPagination";
import { v4 as uuidv4 } from "uuid";
import CustomRow from "./CustomRow";
// import './Search.module.scss'
import "./Table.scss";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { CSVLink } from "react-csv";
import RenderFilterComponent from "./RenderFilterComponent";
import evaluate from "../../util/function/evaluate";
import NewRecord from "../../types/Components/Table/newRecord";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { CloudUpload } from "@mui/icons-material";

import * as React from "react";
import { styled, alpha, useTheme } from "@mui/material/styles";
import EditIcon from "@mui/icons-material/Edit";
import Divider from "@mui/material/Divider";
import ArchiveIcon from "@mui/icons-material/Archive";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Iconify from "../iconify";
const Search =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/search.svg";
const ExcelIcon =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/excel.svg";
// const { TabPane } = Tabs;

interface TableProps<T> {
    config: TableConfig;
    data: T[];
    // functions?: ((rowData: T|string) => string);
    functions: {
        [name: string]: (rowData: T) => void;
    };
    paginationData: {
        nextPage: any;
        prevPage: any;
        setPrevPage: any;
    };
    demography: number;
    loading?: boolean;
    changeTabToAll?: boolean;
    setChangeTabToAll?: any;
}
interface DataItem {
    [key: string]: any;
}

const StyledMenu = styled((props: MenuProps) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
        }}
        transformOrigin={{
            vertical: "top",
            horizontal: "right",
        }}
        {...props}
    />
))(({ theme }) => ({
    "& .MuiPaper-root": {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color:
            theme.palette.mode === "light"
                ? "rgb(55, 65, 81)"
                : theme.palette.grey[300],
        boxShadow:
            "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
        "& .MuiMenu-list": {
            padding: "4px 0",
        },
        "& .MuiMenuItem-root": {
            "& .MuiSvgIcon-root": {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            "&:active": {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity
                ),
            },
        },
    },
}));

function CustomizedMenus({
    buttonArray,
    downloadExcel,
    selectedTab,
}: {
    buttonArray: {
        name: string;
        onClick: () => void;
        icon: JSX.Element;
    }[];
    downloadExcel: any;
    selectedTab: any;
}) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <div>
                <Button
                    id="demo-customized-button"
                    aria-controls={open ? "demo-customized-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={handleClick}
                    className="download___btn"
                    style={{ color: "black" }}
                    // onClick={handleClick}
                >
                    More
                    <MoreVertIcon />
                </Button>
                <StyledMenu
                    id="demo-customized-menu"
                    MenuListProps={{
                        "aria-labelledby": "demo-customized-button",
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                >
                    {downloadExcel && (
                        <MenuItem
                            onClick={() => {
                                downloadExcel.run(selectedTab);
                                handleClose();
                            }}
                            disableRipple
                        >
                            {/* <img
                                src={ExcelIcon}
                                style={{ marginRight: "8px" }}
                            /> */}
                            <Iconify
                            icon={"material-symbols:download"}
                            width={"22px"}
                            height={"22px"}
                            // color={"#464D6A"}
                                style={{ marginRight: "8px" }}
                        />
                            Download
                        </MenuItem>
                    )}

                    {buttonArray.map((item, index) => {
                        return (
                            <MenuItem
                                onClick={() => {
                                    item.onClick();
                                    handleClose();
                                }}
                                disableRipple
                            >
                                {item.icon}
                                {item.name}
                            </MenuItem>
                        );
                    })}
                </StyledMenu>
            </div>
            {/* {openRequestLog && (
                <RequestLog
                    data={logList}
                    requestLogloading={requestLogloading}
                    close={(bool) => setOpenRequestLog(bool)}
                />
            )} */}
        </>
    );
}

const Table = <T extends object>({
    config,
    data,
    functions,
    paginationData,
    demography,
    changeTabToAll,
    loading,
    setChangeTabToAll,
}: TableProps<T>): ReactElement => {
    const [columns, setColumns] = useState<ColumnsType<T>>([]);
    const theme = useTheme();

    const [column, setColumn] = useState<string | null>(
        config?.search?.keys ? config?.search?.keys[0] : null
    );
    const [query, setQuery] = useState<string>("");
    const [activeTab, setActiveTab] = useState(2);
    const [selectedTab, setSelectedTab] = useState("Active");
    const [dataStructure, setDataStructure] = useState<T[]>([]);
    const [selectedRows, setSelectedRows] = useState<T[]>([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [headers, setHeaders] = useState([
        { label: "Employee Id", key: "employeeId" },
        { label: "Name", key: "name" },
        { label: "Gender", key: "gender" },
        { label: "Health Id", key: "tpaId" },
        { label: "Insurer", key: "insurer" },
        { label: "Pending Amount", key: "pendingAmount" },
        { label: "Relationship", key: "relationShip" },
        { label: "Start Date", key: "startDate" },
        { label: "Date of Birth", key: "dob" },
        { label: "Sum Insurance", key: "sumInsured" },
        { label: "Validated", key: "validated" },
        { label: "Status", key: "status" },
        { label: "Pro Rate Amount", key: "ghiAmount" },
        { label: "Actual Amount", key: "actualAmount" },
        { label: "Date of Joining", key: "dateOfJoining" },
        { label: "Date of Resigning", key: "dateOfResigning" },
        { label: "Old Value", key: "oldValue" },
        { label: "New Value", key: "currentValue" },
    ]);
    const anchorRef = useRef<HTMLImageElement>(null);

    useEffect(() => {
        // @ts-ignore
        setColumns((prev) => {
            const newColumns = [
                config.expandable.status && demography > 1
                    ? {
                          title: "",
                          dataIndex: "empty",
                          render: (_: any, record: any) =>
                              !record.children && (
                                  <span style={{ visibility: "hidden" }}>
                                      <ExpandMoreIcon />
                                  </span>
                              ),
                          width: 0,
                          onCell: () => ({
                              style: {
                                  padding: "4px 8px",
                              },
                          }),
                      }
                    : null,
                {
                    title: "",
                    dataIndex: "errors",
                    // @ts-ignore
                    render: (_, record) => {
                        const hasError = !!record?.error;
                        const errorTooltip = hasError ? record.error : "";

                        return (
                            <div>
                                {hasError && (
                                    <Tooltip title={errorTooltip}>
                                        <span className="table___errorIcon">
                                            <ErrorIcon fontSize={"small"} />
                                        </span>
                                    </Tooltip>
                                )}
                            </div>
                        );
                    },
                    onCell: () => ({
                        style: {
                            padding: "0px",
                        },
                    }),
                    width: 0,
                },
                ...config.columns.map((column) => {
                    if (column.condition !== undefined) {
                        const shouldIncludeColumn = evaluate(column.condition, {
                            tab: selectedTab,
                        });
                        if (!shouldIncludeColumn) {
                            return null;
                        }
                    }
                    return {
                        title: column.title,
                        dataIndex: column.dataIndex,
                        key: column.title,
                        align: "left",
                        width: column?.width,
                        render: (text: string, record: T) => {
                            return (
                                <CustomRow
                                    column={column}
                                    text={text}
                                    record={record}
                                    functions={functions}
                                    data={data}
                                />
                            );
                        },
                    };
                }),
            ].filter(Boolean);

            return newColumns;
        });
    }, [config.expandable, selectedTab]);
    useEffect(() => {
        // @ts-ignore
        const activeCount = config?.filter?.keys[1].count;
        // @ts-ignore
        if (activeCount > 0) {
            setSelectedTab("Active");
            setActiveTab(2);
        } else {
            setSelectedTab("All");
            setActiveTab(1);
        }
        // @ts-ignore
    }, [config?.filter?.keys[1]?.count]);
    useEffect(() => {
        if (changeTabToAll) {
            handleTabChange(null, 1);
        }
        const timeoutId = setTimeout(() => {
            setChangeTabToAll(false);
        }, 2000);

        return () => clearTimeout(timeoutId);
    }, [changeTabToAll, config?.filter?.keys[1].count]);
    useEffect(() => {
        let dataSource = data.map((val) => ({
            ...val,
            key: uuidv4(),
        }));
        if (config.expandable.status) {
            setDataStructure(addChildren(dataSource));
        } else {
            setDataStructure(dataSource);
        }
    }, [data, config.expandable]);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener("resize", handleResize);
        handleClose();

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [window.innerWidth]);
    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedRowKeys: number[]) => {
            setSelectedRowKeys(selectedRowKeys);
            setSelectedRows(
                // @ts-ignore
                dataStructure.filter((row) => selectedRowKeys.includes(row.key))
            );
        },
        getCheckboxProps: (record: T) => ({
            // @ts-ignore
            selected: selectedRowKeys.includes(record.key),
        }),
    };

    const handleResponseAlert = (
        status: boolean,
        msg: string,
        duration = 2000
    ) => {
        // @ts-ignore
        alert(msg, status ? "success" : "error");
    };

    const handleSearchInput = (e: any) => {
        setQuery(e.target.value);
    };
    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSearch = () => {
        if (query) {
            config?.search?.run(query, column);
        } else {
            config.filter?.run(selectedTab);
        }
    };
    const renderSearch = () => {
        if (config?.search) {
            return (
                <div className="search___container pr10 pl10 pt4 pb4 ">
                    {config?.search?.keys.length > 1 && (
                        <Dropdown
                            defaultVal={column}
                            items={config?.search?.keys.map((column) => ({
                                id: column,
                                name: column,
                            }))}
                            change={(val: string) => {
                                setColumn(val);
                            }}
                            variant="standard"
                            // className="add_dependent_form_dd"
                        />
                    )}

                    <div className="table__searchbar">
                        <Input
                            placeholder="Search"
                            onChange={(e) => {
                                handleSearchInput(e);
                                if (e.target.value === "") {
                                    config.filter?.run(selectedTab);
                                    if (config?.searchEmptyCall) {
                                        config.searchEmptyCall?.run();
                                    }
                                }
                            }}
                            onPressEnter={handleSearch}
                            className="custom-search-input"
                        />
                        {/* <img
                            src={Search}
                            onClick={handleSearch}
                            className="search___icon"
                            alt=""
                        /> */}
                        <Iconify
                            icon={"material-symbols:search"}
                            onClick={handleSearch}
                            className="search___icon"
                            // width={"15px"}
                            // height={"15px"}
                            // color={"#464D6A"}
                            />
                    </div>
                </div>
            );
        }
        return null;
    };
    const renderPopoverSearch = () => {
        // @ts-ignore
        if (config?.search) {
            const anchorRect = anchorRef.current
                ? anchorRef.current?.getBoundingClientRect()
                : null;
            const popoverStyle = anchorRect
                ? { top: anchorRect.top, left: anchorRect.left }
                : {};
            return (
                <>
                    <img
                        ref={anchorRef}
                        src={Search}
                        onClick={handleClick}
                        className="table___popoverIcon"
                        alt=""
                    />

                    <Popover
                        open={Boolean(anchorEl)}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        PaperProps={{
                            style: {
                                position: "fixed",
                                ...popoverStyle,
                            },
                        }}
                    >
                        {renderSearch()}
                    </Popover>
                </>
            );
        } else return null;
    };
    const handleTabChange = (event: any, val: any) => {
        setActiveTab(val);
        paginationData.setPrevPage([]);

        const selectedTab = config?.filter?.keys.find(
            (tab: any) => tab.key == val
        );

        if (selectedTab) {
            config?.filter?.run(selectedTab.title);
            setSelectedTab(selectedTab.title);
        }
    };
    const handlePageChange = (page: number, currentPage: number) => {
        config?.pagination?.run(
            page,
            selectedTab,
            config?.pagination?.count,
            page > currentPage
        );
    };

    const addChildren = (data: any) => {
        let newData: any = [];
        let prevId: any = null;
        let parent: any = null;

        data.forEach((member: any) => {
            if (member[config.id]?.toLowerCase() !== prevId?.toLowerCase()) {
                parent = {
                    ...member,
                    isParent: true,
                    visibleId: member[config.id],
                };
                newData.push(parent);
            } else {
                if (parent.children === undefined) {
                    parent.children = [];
                }
                parent.children.push({
                    ...member,
                    visibleId: null,
                    isParent: false,
                });
            }
            prevId = member[config.id];
        });

        return newData;
    };

    const customExpandIcon = ({ expanded, onExpand, record }: any) => {
        if (record.children) {
            let handleExpand = () => {
                onExpand(record, !expanded);
            };
            return (
                <span className="pointer" onClick={handleExpand}>
                    {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </span>
            );
        }
    };

    const getRowClassName = (record: NewRecord) => {
        if (
            // @ts-ignore
            record.error &&
            !record.isParent &&
            config.expandable.status
        ) {
            return "table___errorRow highlighted-row";
        }
        // @ts-ignore

        if (record.error) {
            return "table___errorRow";
        }

        if (
            // @ts-ignore
            !record.isParent &&
            config.expandable.status
        ) {
            return "highlighted-row";
        }
        return "";
    };

    const renderDownloadBtn = (): JSX.Element => {
        const handleClick = () => {
            // @ts-ignore
            config.download.run(selectedTab);
        };
        return (
            <Button
                // icon={<FilePresentIcon />}
                className="download___btn"
                onClick={handleClick}
            >
                {/* <img src={ExcelIcon} /> */}
                <Iconify
                    icon={"material-symbols:download"}
                    width={"22px"}
                    height={"22px"}
                    // color={"#464D6A"}
                />
                {config?.download?.title}
            </Button>
        );
    };

    const renderGlobalBtn = (): JSX.Element => {
        return (
            <div className="table__globalBtn">
                {config.globalButtons?.map(
                    (btn, index) =>
                        selectedRows.length > 0 &&
                        (btn.func === "download" ? (
                            // @ts-ignore
                            <CSVLink
                                key={index}
                                onClick={() => {
                                    handleResponseAlert(
                                        true,
                                        "Downloaded selected data!"
                                    );
                                    // @ts-ignore
                                    functions[btn.func](selectedRows);
                                }}
                                headers={headers}
                                data={[...selectedRows]}
                                filename={"data.csv"}
                            >
                                <Button>{btn.display.text}</Button>
                            </CSVLink>
                        ) : (
                            <Button
                                key={index}
                                onClick={() => {
                                    // @ts-ignore
                                    functions[btn.func](selectedRows);
                                }}
                            >
                                {btn.display.text}
                            </Button>
                        ))
                )}
            </div>
        );
    };

    const renderUploadBtn = (): JSX.Element => {
        const handleClick = () => {
            // @ts-ignore
            config.upload.run(selectedTab);
        };
        return (
            <button
                className="buttonPrimary"
                onClick={handleClick}
                style={{ paddingLeft: 14, paddingRight: 14 }}
            >
                <CloudUpload
                    style={{
                        height: "auto",
                        width: "22px",
                    }}
                />
                {config?.upload?.title}
            </button>
        );
    };

    return (
        <div className="table___main">
            <div className="display___flex">
                {config?.serachForDownloadCenter ? 
                    <Box
                    id="search-box"
                    sx={{
                        display: "flex",
                        width: {xs: "100%", sm:'334px' },
                        height: "54px",
                        padding: "0px 20px",
                        alignItems: "center",
                        flexDirection: "row",
                        gap: "8px",
                        alignSelf: "stretch",
                        borderRadius: "46px",
                        border: "1px solid #7F85A3",
                        "&:focus-within": {
                            border: `1px solid ${theme.palette?.brand?.main}`,
                        },
                    }}
                >
                    <Iconify
                        icon={"material-symbols:search"}
                        width={"24px"}
                        height={"24px"}
                        color={"#464D6A"}
                    />
    
                    <TextField
                        variant="outlined"
                        placeholder={`Search document name`}
                        onChange={(e) => {
                            handleSearchInput(e);
                            if (e.target.value === "") {
                                config.filter?.run(selectedTab);
                                if (config?.searchEmptyCall) {
                                    config.searchEmptyCall?.run();
                                }
                            }
                        }}
                        onKeyDown={e=> {if(e.keyCode == 13){
                            handleSearch()}
                        }}
                        sx={{
                            flex: 1,
                            "& .MuiOutlinedInput-root": {
                                "& fieldset": {
                                    border: "none",
                                },
                                "& input::placeholder": {
                                    color: theme.palette.text.primary,
                                },
                            }
                        }}
                    />
                </Box>:(windowWidth <= 1150 ? renderPopoverSearch() : renderSearch())}
                <div className="display___flex">
                    <RenderFilterComponent
                        config={config}
                        activeTab={activeTab}
                        handleTabChange={handleTabChange}
                    />
                    {!config?.extraButtonsArray?.length ? (
                        config?.download && renderDownloadBtn()
                    ) : (
                        <CustomizedMenus
                            buttonArray={config.extraButtonsArray}
                            downloadExcel={config?.download}
                            selectedTab={selectedTab}
                        />
                    )}
                    {config?.globalButtons && renderGlobalBtn()}
                    {config?.upload && renderUploadBtn()}
                </div>
            </div>
            <div>
                <AntTable
                    id="ant__table"
                    columns={columns}
                    rowKey="key"
                    dataSource={dataStructure}
                    pagination={false}
                    className="custom__table"
                    // @ts-ignore
                    rowSelection={config.checkBox ? rowSelection : undefined}
                    // @ts-ignore
                    rowClassName={getRowClassName}
                    loading={loading}
                    expandable={{
                        expandIcon: customExpandIcon,
                    }}
                    scroll={{ y: 595, x: true }}
                />
            </div>

            {dataStructure.length > 0 && config?.pagination?.count && (
                <CustomPagination
                    nextPage={paginationData.nextPage}
                    prevPage={paginationData.prevPage}
                    page={0}
                    pageChange={handlePageChange}
                    tab={selectedTab}
                />
            )}
        </div>
    );
};

export default Table;
